import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { signalrUrl } from '../api/constants';

export function QuizHub(props) {
    const {receivedMessage, message, reset} = props;
    const [doSetupConnection, setDoSetupConnection] = useState(false);
    const [connection, setConnection] = useState(null);
    const [doStartConnection, setDoStartConnection] = useState(false);
    const [connected, setConnected] = useState(false);
    const [sendMessage, setSendMessage] = useState(null);
    const [modal, setModal] = useState(false);
    const [modalMessage, setmodalMessage] = useState("");

    const toggle = () => setModal(!modal);

    const showModal = (modalMessage) => {
        setmodalMessage(modalMessage);
        setModal(true);
    }

    useEffect(() => {
        setDoSetupConnection(true);
    }, []);

    // Reset command via props
    useEffect(() => {
        if (reset)
            setDoSetupConnection(true);
    }, [reset]);

    // Create connection
    useEffect(() => {
        const setupConnection = () => {
            if (!connection) {
                const newConnection = new HubConnectionBuilder()
                    .withUrl(signalrUrl())
                    .withAutomaticReconnect()
                    .build();
                setConnection(newConnection);
            }
        }
        if (doSetupConnection) {
            setupConnection();
            setDoStartConnection(true);
            setDoSetupConnection(false);
        }
        return function cleanup() {
            //TODO: Hur gör man detta?
        };
    }, [doSetupConnection,connection]);

    // Open connection
    useEffect(() => {
        const startConnection = () => {
            if (connection && !connected) {
                connection.start()
                    .then(result => {
                        console.log('Connected!');
                        setConnected(true);
                        connection.on('ReceiveMessage', message => {
                            receivedMessage(message);
                        });
                    })
                    .catch(e => showModal(e.message));
            }
        }
        if (doStartConnection) {
            startConnection();
            setDoStartConnection(false);
        }
    }, [doStartConnection, connection, receivedMessage,connected]);

    // Got message via props
    useEffect(() => {
        if (connected)
            setSendMessage(message);
    }, [connected, message]);

    // Send message
    useEffect(() => {
        const doSendMessage = async () => {
            if (sendMessage != null) {
                if (connection.connectionStarted) {
                    try {
                        await connection.send('SendMessage', sendMessage);
                    }
                    catch (e) {
                        showModal(e.message);
                    }
                }
                else {
                    showModal('Ingen kontakt med servern!');
                }
                setSendMessage(null);
            }
        }
        doSendMessage();
    }, [sendMessage,connection]);

    return (
        <>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Communication</ModalHeader>
                <ModalBody>
                    {modalMessage}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => setDoSetupConnection(true)}>Försök igen</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Avbryt</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

