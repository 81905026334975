import React, { useState, useEffect } from 'react';
import * as adminApi from '../api/adminApi';
import * as c from '../api/constants';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { QuizHub } from './QuizHub';

export function Admin(props) {
    const queryString = new URLSearchParams(window.location.search);
    const quizEventGuid = queryString.get('guid');
    const [doPopulateQuiz, setDoPopulateQuiz] = useState(false);
    const [loading, setLoading] = useState(true);
    const [quiz, setQuiz] = useState(null);
    const [sendMessage, setSendMessage] = useState(null);
    const [modal, setModal] = useState(false);
    const [modalMessage, setmodalMessage] = useState("");

    const toggle = () => setModal(!modal);

    const handleQuiz = (newQuiz) => {
        setQuiz(newQuiz);
        setLoading(false);
        if (newQuiz.error) {
            setmodalMessage(newQuiz.messageText);
            setModal(true);
        }
        else {
            setSendMessage({ eventMode: newQuiz.eventMode, eventId: newQuiz.eventId })
        }
    }

    const nextQuestion = async (next) => {
        if (next && quiz.currentQuestionNo < quiz.noOfQuestions)
            adminApi.nextQuestion(quizEventGuid).then((newQuiz) => {
                handleQuiz(newQuiz);
            });
        else if (!next && quiz.currentQuestionNo > 0)
            adminApi.previousQuestion(quizEventGuid).then((newQuiz) => {
                handleQuiz(newQuiz);
            });
    }

    const setEventMode = async (eventMode) => {
        if (quiz.currentQuestionNo > 0 || eventMode === c.eventModeEnum.End || eventMode === c.eventModeEnum.NotStarted) {
            adminApi.setEventMode(quizEventGuid, eventMode).then((newQuiz) => {
                handleQuiz(newQuiz);
            });
        }
    }

    const restart = async () => {
        adminApi.restartGame(quizEventGuid).then((newQuiz) => {
            handleQuiz(newQuiz);
        });
    };

    const retry = () => {
        if (modal) toggle();
        setDoPopulateQuiz(true);
    };

    useEffect(() => {
        setDoPopulateQuiz(true);
    }, []);

    useEffect(() => {
        const populateQuiz = async () => {
            adminApi.getQuizStatus(quizEventGuid).then((newQuiz) => {
                handleQuiz(newQuiz);
            });
        }
        if (doPopulateQuiz) {
            populateQuiz();
            setDoPopulateQuiz(false);
        }
    }, [doPopulateQuiz, quizEventGuid]);

    const renderQuiz = () => {
        switch (quiz.eventMode) {
            case 0: // NotStarted - Question not shown yet
                return (renderNotStarted());
            case 1: // Open - Question open to answer
                return (renderQuestion());
            case 2: // Locked - Question not open to answer
                return (renderYourAnswer());
            case 3: // Reveal - Reveal if question was answered correctly
                return (renderYourAnswerReveal());
            case 4: // End - Game is over
                return (renderEndOfGame());
            default:
        }
    }

    const buttonColorAnswer = (correct, eventMode) => {
        let reply = "";
        switch (eventMode) {
            case c.eventModeEnum.NotStarted:
                reply = (correct ? "outline-success" : "light");
                break;
            case c.eventModeEnum.Open:
                reply = (correct ? "success" : "outline-success");
                break;
            case c.eventModeEnum.Locked:
                reply = (correct ? "dark" : "outline-dark");
                break;
            case c.eventModeEnum.Reveal:
                reply = (correct ? "warning" : "outline-warning");
                break;
            default:
        }
        return reply;
    }

    const ControlButtons = () => {
        return (
            <>
                <Row className="top-buffer">
                    <Col xs="3"><Button className="btn-block" color="info" onClick={() => { nextQuestion(true); }} size="lg">N&auml;sta</Button></Col>
                    <Col xs="3"><Button className="btn-block" color="success" onClick={() => { setEventMode(c.eventModeEnum.Open); }} size="lg">&Ouml;ppna</Button></Col>
                    <Col xs="3"><Button className="btn-block" color="dark" onClick={() => { setEventMode(c.eventModeEnum.Locked); }} size="lg">L&aring;s</Button></Col>
                    <Col xs="3"><Button className="btn-block" color="warning" onClick={() => { setEventMode(c.eventModeEnum.Reveal); }} size="lg">Avsl&ouml;ja</Button></Col>
                </Row>
                <Row className="top-buffer">
                    <Col xs="3"><Button className="btn-block" color="outline-secondary" onClick={() => { setEventMode(c.eventModeEnum.NotStarted); }} size="lg">St&auml;ng</Button></Col>
                    <Col xs="3"><Button className="btn-block" color="outline-danger" onClick={() => { restart(); }} size="lg">Omstart</Button></Col>
                    <Col xs="3"><Button className="btn-block" color="danger" onClick={() => { setEventMode(c.eventModeEnum.End); }} size="lg">Avsluta</Button></Col>
                    <Col xs="3"><Button className="btn-block" color="outline-info" onClick={() => { nextQuestion(false); }} size="lg">F&ouml;rra</Button></Col>
                </Row>
                <Row className="top-buffer"></Row>
            </>
        )
    }

    const TopList = () => {
        return (
            <>
                <Container>
                    {
                        quiz.participantResultInfoList.map(pri =>
                            <Row key={pri.id} className="top-buffer">
                                <Col>{pri.position}.</Col>
                                <Col>{pri.participantName}</Col>
                                <Col>{pri.points}</Col>
                            </Row>
                        )
                    }
                </Container>
            </>)
    }

    const renderNotStarted = () => {
        if (quiz.currentQuestionNo === 0)
            return (
                <>
                    <Container>
                        <ControlButtons />
                        <Row><Col>
                            <h3><em>Väntar på att quizet ska börja...</em></h3>
                        </Col></Row>
                    </Container>
                    <TopList/>
                </>)
        else
            return (
                <>
                    <Container>
                        <ControlButtons />
                        <Row><Col>
                            <h3>{quiz.currentQuestionNo}. {quiz.messageText}</h3>
                        </Col></Row>
                        {quiz.gameOptions.map(go =>
                            <Row key={go.no} className="top-buffer"><Col><Button className="btn-block" color={buttonColorAnswer(go.correct, c.eventModeEnum.NotStarted)} size="lg" disabled={true} >{go.gameOptionText}</Button></Col></Row>
                        )}
                        <Row><Col>
                            <Row className="top-buffer"><Col><h3 style={{ color: 'blue', textAlign: 'center' }}>Väntar på denna fråga...</h3></Col></Row>
                        </Col></Row>
                    </Container>
                    <TopList />
                </>)
    }

    const renderQuestion = () => {
        return (
            <>
                <Container>
                    <ControlButtons />
                    <Row><Col>
                        <h3>{quiz.currentQuestionNo}. {quiz.messageText}</h3>
                    </Col></Row>
                    {quiz.gameOptions.map(go =>
                        <Row key={go.no} className="top-buffer"><Col><Button className="btn-block" color={buttonColorAnswer(go.correct, c.eventModeEnum.Open)} size="lg" disabled={true} >{go.gameOptionText}</Button></Col></Row>
                    )}
                    <Row><Col>
                        <Row className="top-buffer"><Col><h3 style={{ color: 'green', textAlign: 'center' }}>Öppet för svar!</h3></Col></Row>
                    </Col></Row>
                </Container>
                <TopList />
            </>)
    }

    const renderYourAnswer = () => {
        return (
            <>
                <Container>
                    <ControlButtons />
                    <Row><Col>
                        <h3>{quiz.currentQuestionNo}. {quiz.messageText}</h3>
                    </Col></Row>
                    {
                        quiz.gameOptions.map(go =>
                            <Row key={go.no} className="top-buffer"><Col><Button className="btn-block" color={buttonColorAnswer(go.correct, c.eventModeEnum.Locked)} disabled={true} size="lg" >{go.gameOptionText}</Button></Col></Row>
                        )
                    }
                    <Row className="top-buffer"><Col><h3 style={{ color: 'black', textAlign: 'center' }}>Svar låsta!</h3></Col></Row>
                </Container>
                <TopList/>
            </>)
    }

    const renderYourAnswerReveal = () => {
        return (
            <>
                <Container>
                    <ControlButtons />
                    <Row><Col>
                        <h3>{quiz.currentQuestionNo}. {quiz.messageText}</h3>
                    </Col></Row>
                    {
                        quiz.gameOptions.map(go =>
                            <Row key={go.no} className="top-buffer"><Col><Button className="btn-block" color={buttonColorAnswer(go.correct, c.eventModeEnum.Reveal)} disabled={true} size="lg" >{go.gameOptionText}</Button></Col></Row>
                        )
                    }
                    <Row className="top-buffer"><Col><h3 style={{ color: 'goldenrod', textAlign: 'center' }}>Rätt svar avslöjat!</h3></Col></Row>
                </Container>
                <TopList />
            </>)
    }

    const renderEndOfGame = () => {
        return (
            <Container>
                <ControlButtons />
                <Row><Col>
                    <h2>Spelet är avslutat!</h2>
                </Col></Row>
                <TopList />
            </Container>
        )
    }

    let contents = loading
        ? <p><em>Laddar...</em></p>
        : renderQuiz(quiz);

    return (
        <div>
            {contents}
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Quiz</ModalHeader>
                <ModalBody>
                    {modalMessage}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={retry}>Försök igen</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Avbryt</Button>
                </ModalFooter>
            </Modal>
            <QuizHub message={sendMessage} />
        </div>
    );
}

