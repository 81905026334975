import { handleResponse, handleError } from "./apiUtils";
import { apiUrl } from './constants';

export function getGame(participantGuid, sign) {
    let url = apiUrl() + 'Game?participantGuid=' + participantGuid;
    if (sign != null) {
        url +=  '&sign=' + sign;
    };
    return fetch(url, { method: "GET", mode: "cors" })
        .then(handleResponse)
        .catch(handleError);
}

export function answer(participantGuid, questionNo, answerText) {
    let url = apiUrl() + 'Game?participantGuid=' + participantGuid + '&questionNo=' + questionNo + '&answerText=' + answerText;
    return fetch(url, { method: "POST", mode: "cors" })
        .then(handleResponse)
        .catch(handleError);
}

//export function getEvents(startDate, days, platsId, dataScopeLocation, addDayToEndDate) {
//    return fetch(
//        baseUrl +
//        "?startDate=" +
//        startDate +
//        "&days=" +
//        days +
//        "&platsId=" +
//        (platsId || 0) +
//        "&dataScopeLocation=" +
//        (dataScopeLocation || 0) +
//        "&addDayToEndDate=" +
//        addDayToEndDate,
//        { credentials: "include" }
//    )
//        .then(handleResponse)
//        .catch(handleError);
//}

//export function getEvent(id, addDayToEndDate) {
//    return fetch(baseUrl + id + "?addDayToEndDate=" + addDayToEndDate, { credentials: "include" })
//        .then(handleResponse)
//        .catch(handleError);
//}

//export function getUncompletedEvents(age, platsId, addDayToEndDate) {
//    return fetch(
//        baseUrl +
//        "uncompleted" +
//        "?age=" +
//        age +
//        "&platsId=" +
//        (platsId || 0) +
//        "&addDayToEndDate=" +
//        addDayToEndDate,
//        { credentials: "include" }
//    )
//        .then(handleResponse)
//        .catch(handleError);
//}

//export function saveEvent(event) {
//    const temp = JSON.stringify(event);
//    return fetch(baseUrl + (event.id ? "update/" + event.id : ""), {
//        method: event.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
//        headers: { "content-type": "application/json" },
//        body: temp
//    })
//        .then(handleResponse)
//        .catch(handleError);
//}

//export function moveEvent(event) {
//    const temp = JSON.stringify(event);
//    return fetch(baseUrl + "move/" + event.id, {
//        method: "PUT",
//        headers: { "content-type": "application/json" },
//        body: temp
//    })
//        .then(handleResponse)
//        .catch(handleError);
//}

//export function changeCompleted(id, completed) {
//    return fetch(baseUrl + "changecompleted/" + id + "?completed=" + completed, {
//        method: "PUT",
//        headers: { "content-type": "application/json" }
//    })
//        .then(handleResponse)
//        .catch(handleError);
//}

//export function deleteEvent(eventId) {
//    return fetch(baseUrl + eventId, { method: "DELETE" })
//        .then(handleResponse)
//        .catch(handleError);
//}