import React, { useState, useEffect } from 'react';
import * as gameApi from '../api/gameApi';
import * as c from '../api/constants';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { QuizHub } from './QuizHub';

export function Quiz(props) {
    const queryString = new URLSearchParams(window.location.search);
    const { handleUserName, userNameSet } = props;
    const participantGuid = queryString.get('guid');
    const showDebug = queryString.get('debug');
    const [loading, setLoading] = useState(true);
    const [doPopulateQuiz, setDoPopulateQuiz] = useState(false);
    const [quiz, setQuiz] = useState(null);
    const [message, setMessage] = useState(null);
    const [currentAnswer, setCurrentAnswer] = useState({
        questionNo: 0,
        answerText: "",
        init: true
    });
    const [modal, setModal] = useState(false);
    const [modalMessage, setmodalMessage] = useState("");
    const toggle = () => setModal(!modal);

    //Debug info
    const [debugInfo, setDebugInfo] = useState([]);

    const retry = () => {
        if (modal) toggle();
        setDoPopulateQuiz(true);
    };

    useEffect(() => {
        setDoPopulateQuiz(true);
        setLoading(false);
    }, []);

    useEffect(() => {
        const populateQuiz = async (sign) => {
            gameApi.getGame(participantGuid, sign).then((newQuiz) => {
                if (newQuiz.questionNo !== currentAnswer.questionNo) {
                    setCurrentAnswer({
                        questionNo: newQuiz.questionNo,
                        answerText: newQuiz.answerText,
                        init: true
                    });
                }
                setQuiz(newQuiz);
                handleUserName(newQuiz.participantName);
                if (newQuiz.error) {
                    setmodalMessage(newQuiz.messageText);
                    setModal(true);
                }
            });
        }
        if (doPopulateQuiz) {
            populateQuiz();
            setLoading(false);
            setDoPopulateQuiz(false);
        }
    }, [doPopulateQuiz, currentAnswer.questionNo, participantGuid, handleUserName]);

    useEffect(() => {
        const saveAnswer = async () => {
            gameApi.answer(participantGuid, currentAnswer.questionNo, currentAnswer.answerText).then((newQuiz) => {
                //TODO: Confirm save of answer in some way?
            });
        }
        if (!currentAnswer.init)
            saveAnswer();
    }, [currentAnswer, participantGuid]);

    useEffect(() => {
        if (message !== null) {
            if (message.eventId === quiz.eventId) {
                setDoPopulateQuiz(true);
                if (message.eventMode !== quiz.eventMode) {
                    //    if (message.eventMode === c.eventModeEnum.NotStarted) {
                    //        populateQuiz();
                    //    }
                    //    else {
                    //        let newQuiz = { ...quiz };
                    //        newQuiz.eventMode = message.eventMode;
                    //        setQuiz(newQuiz);
                    //    }
                }
            }
            // Debug info
            if (showDebug) {
                let newChat = [...debugInfo];
                newChat.push({ text: message.eventMode });
                setDebugInfo(newChat);
            }
            setMessage(null);
        }
    }, [message, quiz, showDebug, debugInfo]);

    const buttonClick = (buttonNo) => {
        let theAnswer = "";
        if (quiz.questionType === 0) {
            theAnswer = buttonNo.toString();
        }
        else {
            let selectedButtons = [];
            if (currentAnswer.answerText !== "")
                selectedButtons = currentAnswer.answerText.split(",");
            if (currentAnswer.answerText.includes(buttonNo.toString())) {
                selectedButtons = selectedButtons.filter(ele => ele !== buttonNo.toString());
            }
            else {
                selectedButtons.push(buttonNo);
            }
            if (selectedButtons.length > 0)
                theAnswer = selectedButtons.toString();
        }
        setCurrentAnswer({ questionNo: quiz.questionNo, answerText: theAnswer, init: false });
    }

    const renderQuiz = () => {
        //TODO: Nödlösning. Varför blir det null?
        if (quiz == null) return null;
        switch (quiz.eventMode) {
            case c.eventModeEnum.NotStarted: // Question not shown yet
                return (renderNotStarted());
            case c.eventModeEnum.Open: // Question open to answer
                return (renderQuestion());
            case c.eventModeEnum.Locked: // Question not open to answer
                return (renderYourAnswer());
            case c.eventModeEnum.Reveal: // Reveal if question was answered correctly
                return (renderYourAnswerReveal());
            case c.eventModeEnum.End: // Game is over
                return (renderEndOfGame());
            default:
        }
    }

    const renderNotStarted = () => {
        if (quiz.questionNo === 0)
            return (<><h2>Välkommen {quiz.participantName}!</h2><h3><em>Vi väntar nu på att quizet ska börja...</em></h3></>)
        else
            return (<h3><em>Vi väntar nu på fråga {quiz.questionNo}...</em></h3>)
    }

    const buttonColor = (buttonNo) => {
        if (currentAnswer.answerText && currentAnswer.answerText.includes(buttonNo)) {
            return "primary";
        }
        else {
            return "secondary";
        }
    }

    const buttonColorAnswer = (answer, correct) => {
        if (correct === null) {
            if (answer) {
                return "outline-primary";
            }
            else {
                return "light";
            }
        }
        else {
            if (correct) {
                return "success";
            }
            else if (answer) {
                return "danger";
            }
            else {
                return "light";
            }
        }
    }

    const renderQuestion = () => {
        return (
            <>
                <h3>{quiz.questionNo}. {quiz.messageText}</h3>
                <Container>
                    {quiz.gameOptions.map(go =>
                        <Row key={go.no} className="top-buffer"><Col><Button className="btn-block" color={buttonColor(go.no)} size="lg" onClick={() => buttonClick(go.no)}>{go.gameOptionText}</Button></Col></Row>
                    )}
                </Container>
            </>)
    }

    const renderYourAnswer = () => {
        return (
            <>
                <h3>{quiz.questionNo}. {quiz.messageText}</h3>
                <Container>
                    {
                        quiz.gameOptions.map(go =>
                            <Row key={go.no} className="top-buffer"><Col><Button className="btn-block" color={buttonColorAnswer(go.answer, null)} disabled={true} size="lg" >{go.gameOptionText}</Button></Col></Row>
                        )
                    }
                    {quiz.answerText ?
                        <Row className="top-buffer"><Col><h4 style={{ color: 'blue', textAlign: 'center' }}>Nu är ditt svar låst.</h4></Col></Row>
                        :
                        <Row className="top-buffer"><Col><h4 style={{ color: 'red', textAlign: 'center' }}>Du svarade inte på denna fråga.</h4></Col></Row>
                    }
                </Container>
            </>)
    }

    const getPlace = () => {
        if (quiz.position > 2)
            return "Kämpa på! Du ligger på " + quiz.position + ":e plats";

        else if (quiz.position > 0)
            return "Heja! Du ligger på " + quiz.position + ":a plats!";
        else
            return ""
    }

    const renderYourAnswerReveal = () => {
        return (
            <>
                <h3>{quiz.questionNo}. {quiz.messageText}</h3>
                <Container>
                    {
                        quiz.gameOptions.map(go =>
                            <Row key={go.no} className="top-buffer"><Col><Button className="btn-block" color={buttonColorAnswer(go.answer, go.correct)} disabled={true} size="lg" >{go.gameOptionText}</Button></Col></Row>
                        )
                    }
                    {quiz.answerText ?
                        (quiz.questionPoints > 0 ?
                            <Row className="top-buffer"><Col><h3 style={{ color: 'green', textAlign: 'center' }}>Rätt svar!</h3></Col></Row>
                            :
                            <Row className="top-buffer"><Col><h4 style={{ color: 'red', textAlign: 'center' }}>Det var tyvärr fel!</h4></Col></Row>)
                        :
                        <Row className="top-buffer"><Col><h4 style={{ color: 'red', textAlign: 'center' }}>Du svarade inte på denna fråga.</h4></Col></Row>
                    }
                    <Row className="top-buffer"><Col><h4>Din poäng: {quiz.totalPoints}</h4></Col></Row>
                    <Row className="top-buffer"><Col><h4>{getPlace()}</h4></Col></Row>
                </Container>
            </>)
    }

    const renderEndOfGame = () => {
        return (
            <Container>
                <Row><Col>
                    <h2>Spelet är avslutat!</h2>
                    <h3>Tack {quiz.participantName} för att du deltog.</h3>
                </Col></Row>
            </Container>
        )
    }

    //TODO: Fixa så att denna funkar och endast gör omläsning när det behövs.
    //const handleNewEventMode = (eventMode) => {
    //populateQuiz();
    //    if (eventMode != quiz.eventMode) {
    //        if (eventMode === 0) {
    //            populateQuiz();
    //        }
    //        else {
    //            var newQuiz = { ...Quiz };
    //            newQuiz.eventMode = eventMode;
    //            setQuiz(newQuiz);
    //        }
    //    }
    //}

    let contents = loading
        ? <p><em>Laddar...</em></p>
        : renderQuiz(quiz);

    return (
        <>
            <div>
                {contents}
                {showDebug ?
                    <Container>
                        {
                            debugInfo.map((c, index) =>
                                <Row key={index}><Col>{c.text}</Col></Row>
                            )
                        }
                    </Container>
                    : null}
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Quiz</ModalHeader>
                    <ModalBody>
                        {modalMessage}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={retry}>Försök igen</Button>{' '}
                        <Button color="secondary" onClick={toggle}>Avbryt</Button>
                    </ModalFooter>
                </Modal>
            </div>
            {userNameSet ?
                <QuizHub receivedMessage={(newMessage) => {
                    setMessage(newMessage);
                }} />
                : null}
        </>
    );
}
