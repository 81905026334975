import { handleResponse, handleError } from "./apiUtils";
import { apiUrl } from './constants';

export function getEvent(eventPin) {
    let url = apiUrl() + 'start?eventPin=' + eventPin;
    return fetch(url, { method: "GET", mode: "cors" })
        .then(handleResponse)
        .catch(handleError);
}

export function getParticipantEvent(participantGuid) {
    let url = apiUrl() + 'start/participantevent?participantGuid=' + participantGuid;
    return fetch(url, { method: "GET", mode: "cors" })
        .then(handleResponse)
        .catch(handleError);
}

export function addParticipant(participantName, quizEventId) {
    let url = apiUrl() + 'start?participantName=' + participantName + '&quizEventId=' + quizEventId;
    return fetch(url, { method: "POST", mode: "cors" })
        .then(handleResponse)
        .catch(handleError);
}

export function updateParticipant(particpantGuid, quizEventId, participantName) {
    let url = apiUrl() + 'start?particpantGuid=' + particpantGuid + '&quizEventId=' + quizEventId + '&participantName=' + participantName;
    return fetch(url, { method: "PATCH", mode: "cors" })
        .then(handleResponse)
        .catch(handleError);
}
