import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { QuizHub } from './QuizHub';

export function Chat() {
    const [chat, setChat] = useState([]);
    const [userName, setUserName] = useState("");
    const [chatText, setChatText] = useState("");

    const [sendMessage, setSendMessage] = useState({});

    const [resetHub, setResetHub] = useState(false);    

    const [message, setMessage] = useState(null);

    useEffect(() => {
        if (message !== null && message.eventId === "chat") {
            let newChat = [...chat];
            newChat.push(message);
            setChat(newChat);
            setMessage(null);
        }
    }, [message,chat]);

    const renderChat = () => {
        return (
            <>
                <Container>
                    <Row>
                        <Col>
                            <label htmlFor="nameBox">
                                Ditt namn:
                        </label>
                        </Col>
                        <Col>
                            <input id="nameBox" className="form-control input-lg" type="text" value={userName} autoFocus onChange={(e) => setUserName(e.target.value)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="chatBox">
                                Meddelande:
                        </label>
                        </Col>
                        <Col>
                            <input id="chatBox" className="form-control input-lg" type="text" value={chatText}  autoFocus onChange={(e) => setChatText(e.target.value)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button onClick={() => setSendMessage({ eventId: "chat", userName: userName, text: chatText }) }>Skicka</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button onClick={() => setResetHub(true)}>Reset</Button>
                        </Col>
                    </Row>
                </Container>
                <h3>Chat</h3>
                <Container>
                    {
                        chat.map((c, index) =>
                            <Row key={index}><Col>{c.userName}</Col><Col>{c.text}</Col></Row>
                        )
                    }
                </Container>
                <QuizHub reset={resetHub} message={sendMessage} receivedMessage={(newMessage) => {
                    setMessage(newMessage);
                }} />
            </>)
    }

    return (
        <>
            <div>
                {renderChat()}
            </div>
        </>
    );
}
