export async function handleResponse(response) {
    if (response.ok && response.status === 200) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json();
        } else {
            return response.text();
        }
    }
    else if (response.status === 204) {
        //TODO: Kolla varför vi får 204 (no content) i retur ibland
        alert("Server error: No content!");
    }
    else if (response.status === 401) {
        alert("Access denied!");
        console.error("Access denied!");
    }
    else if (response.status === 400) {
        // So, a server-side validation error occurred.
        // Server side validation returns a string error message, so parse as text instead of json.
        const error = await response.text();
        throw new Error(error);
    }
    else {
        throw new Error("Network response was not ok.");
    };
}

// TODO: Log error
export function handleError(error) {
    // eslint-disable-next-line no-console
    console.error("API call failed. " + error);
    //alert("A server error occured!");
    throw error;
}