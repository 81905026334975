export const eventModeEnum = {
    // Question not shown yet
    NotStarted: 0,
    // Question open to answer
    Open: 1,
    // Question not open to answer
    Locked: 2,
    // Reveal if question was answered correctly
    Reveal: 3,
    // Game is over
    End: 4
}

export const apiUrl = () => {
    if (process.env.NODE_ENV === "production")
        return process.env.REACT_APP_API_URL_PROD;
    else
        return process.env.REACT_APP_API_URL_DEV;
}

export const signalrUrl = () => {
    if (process.env.NODE_ENV === "production")
        return process.env.REACT_APP_SIGNALR_URL_PROD;
    else
        return process.env.REACT_APP_SIGNALR_URL_DEV;
}