import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useStateWithLocalStorage } from './useStateWithLocalStorage';
import * as startApi from '../api/startApi';

export function Start(props) {
    const adminCode = "1212-";
    const [participantGuid, setParticipantGuid] = useStateWithLocalStorage("participantGuid", "");
    const [participantName, setParticipantName] = useStateWithLocalStorage("participantName", "");
    const [quizPin, setQuizPin] = useStateWithLocalStorage("quizPin", "");
    const [pinSubmitted, setPinSubmitted] = useState("");
    const [quizEventInfo, setQuizEventInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showName, setShowName] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalMessage, setmodalMessage] = useState("");
    const toggle = () => setModal(!modal);
    const pinElement = useRef(null);
    const startButtonElement = useRef(null);

    const goToQuiz = (participantGuid) => {
        setLoading(true);
        window.location = '/quiz?guid=' + participantGuid;
    }

    const goToAdmin = (eventGuid) => {
        setLoading(true);
        window.location = '/admin?guid=' + eventGuid;
    }

    const handlePinChange = (event) => {
        setQuizPin(event.target.value);
    }

    const handleNameChange = (event) => {
        setParticipantName(event.target.value);
    }

    const handleNameConfirm = async () => {
        if (participantGuid !== '') {
            let ok = await startApi.updateParticipant(participantGuid, quizEventInfo.id, participantName);
            if (ok)
                goToQuiz(participantGuid);
            else {
                setmodalMessage("Namnet är redan taget av annan tävlande!");
                setModal(true);
            }
        }
        else {
            let newParticipantGuid = await startApi.addParticipant(participantName, quizEventInfo.id);
            if (newParticipantGuid) {
                setParticipantGuid(newParticipantGuid);
                goToQuiz(newParticipantGuid);
            }
            else {
                setmodalMessage("Namnet är redan taget av annan tävlande!");
                setModal(true);
            }
        }
    }

    useEffect(() => {
        setPinSubmitted("auto");
    }, []);

    useEffect(() => {
        const handlePinConfirm = async () => {
            if (!showName) {
                let isAdmin = false;
                let pin = quizPin;
                if (pin.startsWith(adminCode)) {
                    isAdmin = true;
                    pin = pin.substr(adminCode.length);
                }
                let newQuizEventInfo = await startApi.getEvent(pin);
                if (newQuizEventInfo.isMessage) {
                    if (newQuizEventInfo.name === 'incorrectPin') {
                        setmodalMessage('Felaktig pin!');
                    }
                    else {
                        setmodalMessage(newQuizEventInfo.text);
                    }
                    setModal(true);
                }
                else {
                    if (isAdmin) {

                        goToAdmin(newQuizEventInfo.guid);
                    }
                    else {
                        setQuizEventInfo(newQuizEventInfo);
                        setShowName(true);
                        if (quizEventInfo && quizEventInfo.guid !== newQuizEventInfo.guid) {
                            setParticipantGuid("");
                        }
                    }
                }
            }
            else {
                setShowName(false);
            }
        }
        if (pinSubmitted !== "") {
            if (quizPin !== "" && (pinSubmitted === "click" || !quizPin.startsWith(adminCode))) {
                setLoading(true);
                handlePinConfirm();
            }
            setPinSubmitted("");
        }
        setLoading(false);
    }, [pinSubmitted, quizEventInfo, quizPin, showName, setParticipantGuid]);

    useEffect(() => {
        if (pinElement && pinElement.current && !showName) {
            pinElement.current.focus();
        }
    }, [quizEventInfo, showName])

    //useEffect(() => {
    //    if (startButtonElement && startButtonElement.current && showName && participantName !== "") {
    //        startButtonElement.current.focus();
    //    }
    //}, [showName, participantName])

    return (
        loading ? <p><em>Laddar...</em></p> :
            <>
                <Container>
                    <Row>
                        <Col>
                            <h1>Quiz</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>V&auml;lkommen till Magnus Quiz.</p>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row className="top-buffer">
                        <Col xs={{ size: 3 }}>
                            <label htmlFor="pinBox">
                                Quiz PIN:
                        </label>
                        </Col>
                        <Col xs={{ size: 5 }}>
                            <input id="pinBox" className="form-control input-lg" type="text" ref={pinElement} value={quizPin} disabled={showName}
                                onChange={handlePinChange} />
                        </Col>
                        <Col xs={{ size: 4 }}>
                            <Button onClick={() => setPinSubmitted("click")}>{showName ? 'Ändra PIN' : 'OK'}</Button>
                        </Col>
                    </Row>
                </Container>
                {showName ?
                    <Container>
                        <Row className="top-buffer">
                            <Col xs={{ size: 3 }}>
                                <label htmlFor="participantNameBox">
                                    Ditt namn:
                            </label>
                            </Col>
                            <Col xs={{ size: 5 }}>
                                <input id="participantNameBox" className="form-control input-lg" type="text" key="participantNameBox" value={participantName}  onChange={handleNameChange} />
                            </Col>
                            <Col xs={{ size: 4 }}>
                            </Col>
                        </Row>
                        <Row className="top-buffer">
                            <Col>
                                <Button innerRef={startButtonElement} onClick={handleNameConfirm}>Starta</Button>
                            </Col>
                        </Row>
                    </Container> : null}
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Quiz</ModalHeader>
                    <ModalBody>
                        {modalMessage}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggle}>OK</Button>
                    </ModalFooter>
                </Modal>
            </>
    );
}

