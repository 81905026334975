import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Start } from './components/Start';
import { Admin } from './components/Admin';
import { Quiz } from './components/Quiz';
import { Chat } from './components/Chat';

import './custom.css'

export default class App extends Component {
    static displayName = App.name;
    constructor(props) {
        super(props);
        this.state = { userName: '' };
    }

    onHandleUserName = (userName) => {
        if (userName !== this.state.userName)
            this.setState({ userName });
    }

    render() {
        return (
            <Layout userName={this.state.userName}>
                <Route exact path='/' component={Start} />
                <Route path='/quiz' component={() => <Quiz handleUserName={this.onHandleUserName} userNameSet={ this.state.userName !== "" } />} />
                <Route path='/admin' component={Admin} />
                <Route path='/chat' component={Chat} />
            </Layout>
        );
    }
}
