import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { AdminNavMenu } from './AdminNavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    componentDidMount() {
    }

    render() {
        //let queryString = this.props.match.params;
        //let hideNav = queryString.test;
        let queryString = new URLSearchParams(window.location.search);
        let admin = queryString.get('admin');
        return (
            <div>
                { admin ? <AdminNavMenu /> : <NavMenu userName={this.props.userName} />}
                <Container>
                    {this.props.children}
                </Container>
            </div>
        );
    }
}
