import { handleResponse, handleError } from "./apiUtils";
import { apiUrl } from './constants';

export function getQuizStatus(quizEventGuid) {
    let url = apiUrl() + 'game/admin/getquizstatus?quizEventGuid=' + quizEventGuid;
    return fetch(url, { method: "POST", mode: "cors" })
        .then(handleResponse)
        .catch(handleError);
}

export function setEventMode(quizEventGuid, eventMode) {
    let url = apiUrl() + 'game/admin/seteventmode?quizEventGuid=' + quizEventGuid + '&eventmode=' + eventMode.toString();
    return fetch(url, { method: "POST", mode: "cors" })
        .then(handleResponse)
        .catch(handleError);
}

export function nextQuestion(quizEventGuid) {
    let url = apiUrl() + 'game/admin/setnextquestion?quizEventGuid=' + quizEventGuid;
    return fetch(url, { method: "POST", mode: "cors" })
        .then(handleResponse)
        .catch(handleError);
}

export function previousQuestion(quizEventGuid) {
    let url = apiUrl() + 'game/admin/setpreviousquestion?quizEventGuid=' + quizEventGuid;
    return fetch(url, { method: "POST", mode: "cors" })
        .then(handleResponse)
        .catch(handleError);
}

export function restartGame(quizEventGuid) {
    let url = apiUrl() + 'game/admin/restartgame?quizEventGuid=' + quizEventGuid;
    return fetch(url, { method: "POST", mode: "cors" })
        .then(handleResponse)
        .catch(handleError);
}